
  
  .login-form {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
  }
  
  .logo-container {
    text-align: left;
  }
  
  .logo {
    max-width: 100%;
    height: auto;
    padding-top: 20px;
  }
  

  .text-header p:first-child {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .text-header p:nth-child(2){
    color: #757575;
    /* font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

.formControl {
    display: inline-flex ;
    /* align-items: center ; */
    gap: 10px ;
    border-radius: 999px !important;
    border: 1px solid #E0E0E0 !important; 
    background: #FFF ;
    padding: 10px 10px !important;
    border-color: #E0E0E0;

  }

  .formControl:focus {
    border-color: #F7941D !important;
  }
  .formLabel{
    color: #3D3D3D;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* Add the following style for the eye-icon class */
.eye-icon {
    position: absolute;
    top: 70%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .rememberme{
        color: #808493 !important;
    /* font-family: Source Sans Pro !important; */
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 180.523% !important; 
  }
  
  .checkbox-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .signin-btn {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
        border-radius: 999px !important;
    background: linear-gradient(135deg, #FF5714 0.55%, #F88F2A 99.42%);
  }

  .signup-btn {
    width: 80%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
        border-radius: 999px !important;
    background: linear-gradient(135deg, #FF5714 0.55%, #F88F2A 99.42%);
  }
  
  .signup-text {
    margin-top: 10px;
    color: #8B8B8B;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 166.523%; /* 26.644px */
  }
  
  .background-image {
    background: url("../../assets/loginback.png") center center/cover no-repeat;
    color: #fff;
    position: relative;
    
  }
  
  .background-image h4 {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .background-image p {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    margin: 15px;
    font-weight: 400;
  }
  
  .text {
    width: 552px;
    height: 199px;
    flex-shrink: 0;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(25px);
    position: absolute;
    bottom: 0;
    margin-bottom: 50px;
    left: 50%; /* Horizontally center */
    transform: translateX(-50%); /* Horizontally center */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    text-align: center;
  }

  
  /* Add this to your login.css file */

.password-strength {
  display: flex;
  margin-bottom: 10px;
}

.strength-line {
  flex-grow: 1;
  height: 10px;
  margin-right: 2px;
  border-radius: 50px;
  background-color: #d9d9d9; /* Default color for inactive lines */
}

.strength-line.active {
  border-radius: 3px;
  background: #F7941D;
}
