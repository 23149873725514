/* OTP.css */

.otp-input {
    width: 60px;
    height: 70px;
    font-size: 16px;
    text-align: center;
    margin-right: 45px;
    justify-content: space-between;
  }
  
  .otp-input.filled {
    background-color: black;
    color: white;
  }
  