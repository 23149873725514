.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  /* width: 250px; */
  border-right: 1px solid #ddd;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
  overflow-y: auto; /* Added overflow-y to enable scrolling if content exceeds 100vh */
}

.sidebar.open {
  left: 0;
}

.sidebar.closed {
  left: -250px;
}

.navbar-brand {
  margin-bottom: 0;
}

.nav-link {
  padding: 0.7rem 1rem;
  color: #333;
}

.icon {
  margin-right: 25px;
  color:"red",
  
}

.line {
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}

.nav-main {
  margin: 10px;
}

.links-main {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  flex-shrink: 0;
  border-radius: 999px;
  margin-top: 10px;
  padding: 0 20px;
  text-decoration: none;
  color: #323232;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* justify-content: space-between; */
/* text-align: left; */
/* text-align: center; */

.links-main.active {
  background: #FFE6C8;
  color: #F15822;
}

.links-main span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.lgout{
  background-color: #FFD7D7;
  color: #D43E3E;
  cursor: pointer;
}