thead th {
    background-color: #EBEDF1 !important;
    height: 40px;
    color: #9197A5 !important;
    font-size: 14px;
    font-weight: 400;
    
}



/* tbody tr:hover td { */
    /* background-color: rgb(255, 225, 213); */
/* } */

tbody td{
    color: #5A5A5A;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 12px; 
height: 60px; /* Adjust the height to your preference */
vertical-align: middle; /* Vertically align the text in the middle */
}





.productName{
    color: #1D2129;
    font-weight: 600;
    line-height: 16px; 
    margin-left: 5px;
}

/* Style for "In stock" status */
.in-stock {
    background-color: #C7FFD4 !important;
    color: #00B42A !important;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border-radius: 5px;
    padding: 10px;
  }
  
  /* Style for "Not available" status */
  .not-available {
    background-color: #dbc5bd !important;
    color: #e23131 !important;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border-radius: 5px;
    padding: 10px;
  }
  


  /* AddGarage.css */

.step-titles {
  border:"1px solid red";
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    max-width: 40%;
    margin: 0 auto;
  }
  
  .line2 {
    border-top: 2px solid #AFB5BF;
    flex-grow: 1;
    margin: 0 10px;
  }
  
  .active-step {
    color: #F15822;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  
  /* styles.css */

.custom-input-style {
  display: inline-flex !important;
  height: 44px !important;
  padding: 15px 202px 14px 20px;
  align-items: center !important;
  flex-shrink: 0 !important;
  border-radius: 999px !important; 
  border: 1px solid #D3DAE7 !important;
  background: #F3F7FF !important;
}


.serviceFormControl{
  align-items: center !important; 
  justify-content: flex-end !important;
  padding: 12px !important;
  border-radius: 25px !important;
  border: 1px solid #D3DAE7 !important;
  background: #F3F7FF  !important;
}

.serviceformtewsxt{
  color: #8F939B;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.serviceFormheader{
  color: #000;
font-size: 18px !important; 
font-style: normal;
font-weight: 600 !important;
line-height: 15.4px;
}

.serviceFormheader2{
  color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 15.4px; /* 96.25% */
}


.circular-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.upload-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.selected-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.label-txt{
  color: rgba(104, 108, 118, 1);
  font-size: 18px;
  font-weight: 500;
}


/* CSS for custom modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-main {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  max-width: 600px;
}

.search-box {
  box-sizing: "border-box";
  border: "1px solid transparent";
  width: "100%";
  height: "32px";
  padding: "0 12px";
  borderRadius: "3px";
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)";
  fontSize: "14px";
  outline: "none";
  backgroundColor: "white";
}

.close-button, .confirm-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}



/* Scroll bar */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 90, 13); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}


/* Scroll bar end */

.btn-close {
  margin: 0 !important;
}
